
export const sysKeys = {
  index: '序号',
  productCategoryName: '大类',
  name: '品名',
  styleName: '款式',
  sizeName: '尺码',
  lowerLimitPrice: '最小价格'
}

export const spanCols = {
  index: '序号',
  productCategoryName: '大类',
  name: '品名',
  styleName: '款式'
}

export const requiredCols = [sysKeys.sizeName, sysKeys.lowerLimitPrice]